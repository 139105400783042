import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { IconCopyButtonFields } from "../../../fragments/iconCopyButtonFields";
import { WMKGradient, colors } from "../../../vars/palette";
import { useRef, useEffect, useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useLayoutContext } from "../../../context/LayoutContext";
import { BlockIconButtonsRowFields } from "../../../fragments/blockIconButtonsRowFields";
import { AiOutlineRight } from "react-icons/ai";

const WrapContainer = styled(Container)<{ isstuck?: "true" }>`
  padding-top: ${({ isstuck }) => (isstuck ? `1rem` : `2rem`)};
  padding-bottom: ${({ isstuck }) => (isstuck ? `0.25rem` : `2rem`)};
  padding-left: 0;
  padding-right: 0;
  .intro-container {
    font-size: 16px;
    padding: 0 1.5rem;
    margin: 1.5rem 0;
    a {
      display: inline-block;
      padding: 0.25rem 2.5rem;
      border: 2px solid ${colors.hex("secondary")};
      color: ${colors.hex("secondary")};
      text-decoration: none;
      transition: all 0.3s ease;
      &:hover {
        ${WMKGradient.grad()}
        color: white;
        border: 2px solid rgba(0, 0, 0, 0) !important;
        transition: all 0.3s ease;
      }
    }
  }
  .nav-links {
    justify-content: center;
  }
  @media screen and (max-width: 766px) {
    padding-top: ${({ isstuck }) => (isstuck ? `0rem` : `2rem`)};
    padding-bottom: 0;
  }
`;
const BreadcrumbCol = styled(Col)`
  padding: 0 1.5rem;
  display: flex;
  a {
    color: ${colors.hex("text")};
    text-decoration: none;
    &:hover {
      color: ${colors.hex("secondary")};
    }
  }
  strong {
    font-weight: bold;
    // @media screen and (max-width: 900px) {
    //   font-size: 0.8rem;
    // }
  }
  @media screen and (max-width: 991px) {
    font-size: 0.9rem;
  }
`;
const NavCol = styled(Col)`
  display: flex;

  @media screen and (max-width: 760px) {
    padding: 1rem 0 0 0;
  }
  @media screen and (min-width: 760px) and (max-width: 1400px) {
    font-size: 1rem;
    .col-auto {
      padding: 0 1vw;
    }
  }
  a {
    @media screen and (min-width: 1000px) {
      margin-left: 1.5rem;
    }
    margin-bottom: 1vh;
    display: block;
    color: ${colors.hex("text")};
    // font-weight: bold;
    text-decoration: none;
    padding-top: calc(0.25rem + 4px);
    &:hover {
      color: ${colors.hex("secondary")};
    }
  }
  p {
    @media screen and (min-width: 1000px) {
      margin-left: 1.5rem;
    }
    margin-bottom: 1vh;
    // color: ${colors.hex("secondary")};
    // text-decoration: underline;
    border-top: 4px solid #EA682E;
    padding-top: 0.25rem;
  }
`;

const NavWrap = styled.div`
  padding: 0;
  a {
    margin-bottom: 1vh;
    display: block;
    color: #606060;
    text-decoration: none;
    &:hover {
      color: ${colors.hex("secondary")};
    }
  }
  .navToggle {
    border: none;
    width: 100%;
    padding: 1rem 1rem 1rem 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    background: white;
    box-shadow: 0 -2px 10px #f3f3f3;
    color: rgb(96, 96, 96);
    p {
      text-align: left;
      margin: 0;
      font-weight: 600;
    }
    span {
      color: ${colors.hex("secondary")};
      transition: transform 0.5s ease;
      font-size: 30px;
      line-height: 19px;
    }
  }
`;

const NavDrawer = styled.div`
  background: #f3f3f3;
  padding: 1.5rem 2rem;
  transition: transform 0.5s ease;
  font-weight: 600;
  p {
    color: ${colors.hex("secondary")};
    font-weight: 600;
  }
`;

const SolutionNav = ({
  currPage,
  refHeight,
  setRefHeight,
  isStuck,
  setIsStuck,
  viewWidth,
  introduction,
  link,
  data,
  isServicePage
}: {
  currPage?: string;
  refHeight: number;
  setRefHeight: Function;
  isStuck: boolean;
  setIsStuck: Function;
  viewWidth: number;
  introduction?: string;
  link?: string;
  data: BlockIconButtonsRowFields
  isServicePage?: boolean
}) => {
  const context = useLayoutContext();
  // const data: SolQuery = useStaticQuery(solutionsQuery);
  const solutions = data.buttons;
  const [scrollPos, setScrollPos] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const navRef = useRef<HTMLDivElement>();
  const navWrapRef = useRef<HTMLDivElement>();
  const handleIsStuck = () => {
    setIsStuck(scrollPos >= navRef?.current?.getBoundingClientRect().y);
  };

  solutions.sort((a, b) => a.title.localeCompare(b.title));

  useEffect(() => {
    handleIsStuck();
  }, [scrollPos, context]);

  useScrollPosition(
    ({ currPos }) => {
      setScrollPos(-currPos.y);
    },
    [navRef]
  );

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [navWrapRef]);
  const handleResize = () => {
    const current = navWrapRef?.current;
    const rect = current ? current.getBoundingClientRect() : null;
    setRefHeight(current ? rect.height : refHeight);
  };

  const handleToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Container
      style={
        isStuck
          ? {
              position: "fixed",
              top: context.headerHeight,
              left: 0,
              zIndex: 1000,
              backgroundColor: "white",
              width: "100vw",
              boxShadow: `2px 5px 10px rgba(0,0,0,.25)`,
              padding: 0,
            }
          : {
              position: "relative",
              padding: 0,
              boxShadow: `2px 5px 10px rgba(0,0,0,.25)`,
              zIndex: 1
            }
      }
      ref={navRef}
      fluid
    >
      <WrapContainer
        isstuck={isStuck ? "true" : undefined}
        ref={navWrapRef}
        fluid={viewWidth < 1200 ? true : false}
      >
        <Row style={{ margin: 0 }}>
          {(!isStuck && viewWidth < 767 || viewWidth >= 767) && (
            <>
              <BreadcrumbCol xs={12} lg={4} md={3}>
                {isServicePage ?<WmkLink to="/services">{`Services `}</WmkLink> : <WmkLink to="/solutions">{`Solutions `}</WmkLink>}
                &nbsp;/&nbsp;
                <strong>{`${currPage}`}</strong>
              </BreadcrumbCol>
              {introduction && viewWidth < 768 && (
                <div className="intro-container">
                  <p>{introduction}</p>
                  <WmkLink to={link ? `/${link}` : "#solutions-form"}>
                    GET STARTED
                  </WmkLink>
                </div>
              )}
            </>
          )}
          {viewWidth < 767 && (
            <NavWrap>
              <button className="navToggle" onClick={() => handleToggle()}>
                <p>
                  VIEW OUR SOLUTIONS{" "}
                  <span>
                    <AiOutlineRight
                      style={
                        isDrawerOpen
                          ? {
                              transform: "rotate(90deg)",
                              transition: "transform .3s ease",
                            }
                          : {
                              transform: "rotate(0deg)",
                              transition: "transform .3s ease",
                            }
                      }
                    />
                  </span>
                </p>
              </button>
              {isDrawerOpen && (
                <NavDrawer>
                  {solutions.map((sol: IconCopyButtonFields, i: number) => {
                    const to = isServicePage ? `/services/${sol.linksTo.slug}` : `/solutions/${sol.linksTo.slug}`;
                    const title = sol.title;
                    const isCurrSol =
                      currPage &&
                      currPage.toLowerCase().includes(title.toLowerCase());
                    return isCurrSol ? (
                      <Col xs="auto" key={`sol-nav-item-${i}`}>
                        <p>{title}</p>
                      </Col>
                    ) : (
                      <Col xs="auto" key={`sol-nav-item-${i}`}>
                        <WmkLink to={to}>{title}</WmkLink>
                      </Col>
                    );
                  })}
                </NavDrawer>
              )}
            </NavWrap>
          )}
          {viewWidth >= 767 && (
            <NavCol xs={12} lg={8} md={9} className="nav-links">
              <Row>
                {solutions.map((sol: IconCopyButtonFields, i: number) => {
                  const to = isServicePage ? `/services/${sol.linksTo.slug}` : `/solutions/${sol.linksTo.slug}`;
                  const title = sol.title;
                  const isCurrSol =
                    currPage &&
                    currPage.toLowerCase().includes(title.toLowerCase());
                  return isCurrSol ? (
                    <Col xs="auto" key={`sol-nav-item-${i}`}>
                      <p>{title}</p>
                    </Col>
                  ) : (
                    <Col xs="auto" key={`sol-nav-item-${i}`}>
                      <WmkLink to={to}>{title}</WmkLink>
                    </Col>
                  );
                })}
              </Row>
            </NavCol>
          )}
        </Row>
      </WrapContainer>
    </Container>
  );
};

export default SolutionNav;

interface SolQuery {
  block: BlockIconButtonsRowFields;
}

// const solutionsQuery = graphql`
//   query {
//     block: contentfulBlockIconButtonsRow(
//       title: { eq: "Solutions Page Icon Buttons" }
//     ) {
//       ...BlockIconButtonsRowFields
//     }
//   }
// `;
