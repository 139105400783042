import { SolutionFields } from "../fragments/nodeSolutionFields";
import { RichText } from "wmk-rich-text";
import { Img } from "wmk-image";

export class Solution {
  title: string;
  slug: string;
  heroImage: Img;
  heroVideo: {
    file: {
      url: string;
    };
  } | null;
  introduction?: string;
  headerLink?: string;
  content?: RichText;
  meta?: string;
  formHeader?: string;
  formDescription?: string;
  constructor(node: SolutionFields) {
    const _node = { ...node };
    this.title = _node.title;
    this.slug = _node.slug;
    this.heroImage = new Img(_node.heroImage);
    this.heroVideo = _node.heroVideo;
    this.introduction = _node.introduction
      ? _node.introduction.introduction
      : "";
    this.headerLink = _node.headerLink ? _node.headerLink.slug : "";
    this.content = new RichText(_node.content);
    this.meta = _node.meta;
    this.formHeader = _node.formHeader;
    this.formDescription = _node.formDescription
      ? _node.formDescription.formDescription
      : "";
  }
}
