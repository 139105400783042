import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { Img, WmkImage } from "wmk-image";
import { colors, WMKGradient } from "../../../vars/palette";
import Button from "../../common/GibsonButton";
import { WmkLink } from "wmk-link";

const Wrap = styled.div`
  position: relative;
  color: ${colors.hex("white")};
  overflow: hidden;
`;
const BgWrap = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  video {
    position: relative;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
const GradientOverlay = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  background: ${WMKGradient.grad()};
`;

const HeaderContainer = styled(Container)`
  padding: 2rem 0;
  padding-left: var(--bs-gutter-x, 0.75rem);
  position: relative;
  .row {
    margin: 0 1rem;
  }
  .link-col {
    display: flex;
    justify-content: center;
    a {
      display: inline-block;
      padding: 0.25rem 2.5rem;
      border: 2px solid ${colors.hex("white")};
      color: ${colors.hex("white")};
      text-decoration: none;
      transition: all 0.3s ease;
      text-align: center;
      &:hover {
        background: ${colors.hex("white")};
        color: ${colors.hex("secondary")};
        border: 2px solid rgba(0, 0, 0, 0) !important;
        transition: all 0.3s ease;
      }
    }
    @media only screen and (max-width: 991px) {
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (max-width: 760px) {
    padding: 3vh 0;
    padding-left: calc(var(--bs-gutter-x, 0.75rem));
  }
  @media screen and (max-width: 575px) {
    padding: 3vh 0;
    padding-left: calc(var(--bs-gutter-x, 0.5rem) + 15px);
  }
`;

const SolutionHero = ({
  currPage,
  image,
  video,
  width,
  link,
  copy,
}: {
  currPage: string;
  image?: Img;
  video?: {
    file: {
      url: string;
    };
  };
  width: number;
  link?: string;
  copy: string;
}) => {
  const [viewWidth, setViewWidth] = useState(0);
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };
  return (
    <Wrap>
      <BgWrap>
        {/* {image && <WmkImage image={image} />} */}
        {video?.file?.url && viewWidth > 767 ? (
          <ReactPlayer
            url={video.file.url}
            width={`${width}px`}
            height={`${width / 3.57}px`}
            playing
            loop
          />
        ) : image ? (
          <WmkImage image={image} />
        ) : (
          <></>
        )}
        <GradientOverlay />
      </BgWrap>
      <HeaderContainer fluid>
        <Row>
          <h1>{currPage}</h1>
          {viewWidth > 767 && (
            <>
              <Col md={9}>
                <p>{copy}</p>
              </Col>
              <Col className="link-col" md={3}>
                <WmkLink to={link ? `/${link}` : "#solutions-form"}>
                  GET STARTED
                </WmkLink>
              </Col>
            </>
          )}
        </Row>
      </HeaderContainer>
    </Wrap>
  );
};

export default SolutionHero;
